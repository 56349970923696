import { useState, useEffect, useCallback } from 'react';

import GoogleMapReact from 'google-map-react';

import './App.css';

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const googleMapsDefault = {
  center: { lat: 34.0522, lng: -118.2437 },
  zoom: 15
};

function MapMarker (props) {
  return <div>{props.text}</div>;
}


function App() {

  // Google Maps API Map and Client instances
  let [googleMapsIsLoading, setGoogleMapsIsLoading] = useState(true);
  let [googleMapsInstance, setGoogleMapsInstance] = useState(undefined);
  let [googleMapsClient, setGoogleMapsClient] = useState(undefined);
  let onGoogleApiLoaded = useCallback(({map, maps}) => {
    setGoogleMapsInstance(map);
    setGoogleMapsClient(maps);
    setGoogleMapsIsLoading(false);
  });

  // Places Service
  let [placesService, setPlacesService] = useState(undefined);
  function isPlacesServiceReady() {
    return !!placesService;
  }
  function getNearbySearch(request) {
    return new Promise((resolve, reject) => {
      try {
        placesService.nearbySearch(request, (response, status) => {
          resolve({response, status})
        });
      } catch (err) {
        reject(err);
      }
    });
  }
  useEffect(() => {
    if (!(googleMapsClient && googleMapsInstance)) {
      setPlacesService(undefined);
      return;
    }
    setPlacesService(new googleMapsClient.places.PlacesService(googleMapsInstance));
  }, [googleMapsInstance, googleMapsClient]);

  // Search Terms Form
  const [newSearchTerm, setNewSearchTerm] = useState('');
  function onChangeNewSearchTerm(event) {
    setNewSearchTerm(event.target.value);
  }
  const [searchTerms, setSearchTerms] = useState([]);
  function onSubmitNewSearchTerm(event) {
    event.preventDefault();
    setSearchTerms([newSearchTerm, ...searchTerms]);
    setNewSearchTerm('');
  }

  // Center coordinates
  const [centerCoordinates, setCenterCoordinates] = useState(googleMapsDefault.center);

  // 'Find Hidden Hubs' Button
  const [mapMarkers, setMapMarkers] = useState([]);
  let onClickFindHiddenHubsButton = useCallback(async () => {
    if (searchTerms.length < 1) {
      alert('Please add a search');
      return;
    }
    console.log('center coords', centerCoordinates);
    const allSearchResults = await Promise.all(searchTerms
      .map((term) => ({
        keyword: term,
        location: new googleMapsClient.LatLng(centerCoordinates.lat, centerCoordinates.lng),
        radius: '5000'
      }))
      .map(async (request) => ({
        keyword: request.keyword,
        result: await getNearbySearch(request)
      }))
    );
    console.log('all', allSearchResults);
    const newMapMarkers = allSearchResults.reduce((acc, searchResults) => {
      const { keyword, result } = searchResults;
      return [...acc, ...result.response.map(resultItem => ({
        lat: resultItem.geometry.location.lat(),
        lng: resultItem.geometry.location.lng(),
        text: keyword
      }))];
    }, []);
    setMapMarkers(newMapMarkers);

    // Find the smallest hidden hub by finding the smallest member in the cartesian product
    // Find the cartesian product of all search results
    let cartesianProduct = [];
    for (let searchResult of allSearchResults) {
      const { result } = searchResult;
      const resultItems = result.response;
      if (cartesianProduct.length === 0) {
        cartesianProduct = resultItems.map(item => [item]);
        continue;
      }
      let newCartesianProduct = [];
      for (let product of cartesianProduct) {
        for (let resultItem of resultItems) {
          newCartesianProduct.push([...product, resultItem]);
        }
      }
      cartesianProduct = newCartesianProduct;
    }
    console.log('cartesian', cartesianProduct);

    let getSize = (product) => {
      let lats = product.map(i => i.geometry.location.lat());
      let latRange = Math.max(...lats) - Math.min(...lats);
      let lngs = product.map(i => i.geometry.location.lng());
      let lngsRange = Math.max(...lngs) - Math.min(...lngs);
      return latRange * lngsRange;
    };
    let smallestProduct = undefined;
    let smallestSize = undefined;
    for (let product of cartesianProduct) {
      const currentSize = getSize(product);
      if (!smallestProduct || currentSize < smallestSize) {
        smallestProduct = product;
        smallestSize = currentSize;
      }
    }
    console.log('smallest hub', smallestProduct);
    let latlngPoints = smallestProduct.map(i => ({
      lat: i.geometry.location.lat(),
      lng: i.geometry.location.lng()
    }));
    const polygon = new googleMapsClient.Polygon({
      paths: latlngPoints,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
    });
    polygon.setMap(googleMapsInstance);

  }, [searchTerms, centerCoordinates, placesService]);


  return (
    <div className="h-screen w-full flex overflow-hidden">
      <nav className="flex flex-col bg-gray-200 dark:bg-gray-900 w-auto px-12 pt-4 pb-6">
        {/* <!-- SideNavBar --> */}

        <div className="flex flex-row border-b items-center place-content-around pb-2">
          {/* <!-- Header --> */}
          <span className="text-lg font-semibold capitalize dark:text-gray-300">
            Hidden Hubs
          </span>
        </div>

        <div className="my-8">
          <form className="pt-2 relative mx-auto text-gray-600" onSubmit={onSubmitNewSearchTerm}>
            <input className="border-2 border-gray-300 bg-white h-10 px-5 pr-8 rounded-lg text-sm focus:outline-none" type="search" name="search" placeholder="Search" value={newSearchTerm} onChange={onChangeNewSearchTerm} />
            <button type="submit" className="absolute right-0 top-0 mt-5 mr-4">
              <svg className="text-gray-600 h-4 w-4 fill-current"
                version="1.1" id="Capa_1" x="0px" y="0px"
                viewBox="0 0 56.966 56.966" style={{'enableBackground':'new 0 0 56.966 56.966'}}
                width="512px" height="512px">
                <path
                  d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
              </svg>
            </button>
          </form>
        </div>

        <h3 className="font-semibold capitalize dark:text-gray-600">search list</h3>
        <ul className="mt-2 text-gray-600">
          { searchTerms.length === 0 && <li className="text-sm">Use the search bar to add <br /> searches to this list.</li>}
          {searchTerms.map((term) => (
            <li key={term} className="bg-white p-2 rounded my-1 border-b border-grey cursor-pointer hover:bg-grey-lighter">
              {term}
            </li>
          ))}
        </ul>

        <div className="mt-auto flex items-center text-gray-700 dark:text-gray-400">
          {/* <!-- important action --> */}
          <button className="mr-5 bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-6 rounded" onClick={onClickFindHiddenHubsButton} disabled={googleMapsIsLoading}>
            <span className="capitalize font-medium">Find Hidden Hubs!</span>
          </button>
        </div>
      </nav>
      <main
        className="flex-1 flex flex-col bg-gray-100 dark:bg-gray-700 transition
        duration-500 ease-in-out overflow-y-auto">
        <div className="mx-10 my-2">
          <nav
            className="flex flex-row justify-between border-b
            dark:border-gray-600 dark:text-gray-400 transition duration-500
            ease-in-out">
            <div className="flex">
              {/* <!-- Top NavBar --> */}
              <a
                href="#"
                className="py-2 block text-green-500 border-green-500
                dark:text-green-200 dark:border-green-200
                focus:outline-none border-b-2 font-medium capitalize
                transition duration-500 ease-in-out">
                map
              </a>
              <button
                className="ml-6 py-2 block border-b-2 border-transparent
                focus:outline-none font-medium capitalize text-center
                focus:text-green-500 focus:border-green-500
                dark-focus:text-green-200 dark-focus:border-green-200
                transition duration-500 ease-in-out">
                list
              </button>
            </div>
          </nav>

          <div style={{ height: '90vh', width: '100%' }}>
            <GoogleMapReact bootstrapURLKeys={{
                key: GOOGLE_API_KEY,
                libraries: ['places']
              }}
              defaultCenter={googleMapsDefault.center}
              center={centerCoordinates}
              defaultZoom={googleMapsDefault.zoom}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={onGoogleApiLoaded}
            >
              {mapMarkers.map((m) => <MapMarker key={`${m.lat}-${m.lng}`} lat={m.lat} lng={m.lng} text={m.text}></MapMarker>)}
            </GoogleMapReact>
          </div>

        </div>

      </main>

    </div>
  );
}

export default App;

